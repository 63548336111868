<template>
  <section>
    <!--
    <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Ingreso Programa"></screenshot>

    <div>
      {{ demand_detail.fields }}
    </div>


-->

    <BrunaForm
      ref="configRef"
      v-bind:fields="this.config.fields"
      :card="this.config.card"
      @change="configChange"
    ></BrunaForm>

   
    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>


import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";

import Vue from 'vue'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
   
  },

  data()
  {

    

    return {

      config: {
        card: {
          title: "Config",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Tier 1",
            type: "input",
            id: 'tier1',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },

          {
            label: "Tier 2",
            type: "input",
            id: 'tier2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },


          {
            label: "Tier 3",
            type: "input",
            id: 'tier3',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },

          {
            label: "Tier 4",
            type: "input",
            id: 'tier4',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },

          {
            label: "Comisiones (%)",
            type: "input",
            id: 'comisiones_percent',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },

          {
            label: "Gasto Bodega USD/LB",
            type: "input",
            id: 'gasto_bodega_usd_lb',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },


          {
            label: "Gasto Aduana USD/LB",
            type: "input",
            id: 'gasto_aduana_usd_lb',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 3
            },
            value: null
          },



        ]
      },





    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    CosechaUrgente,
    Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    BrunaForm,
    BrunaFormRepeat
  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {

    configChange(){},




    formatData()
    {

      return {
        demand_detail: this.demand_detail.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        buyer: this.buyer.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        order: this.order.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        sku: this.sku.items.map(item => item.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})),
        price_program: this.price_program.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        price_spot: this.price_spot.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        forecast: this.forecast
      }


    },

   

    save()
    {

      console.log(JSON.stringify(this.formatData(), null, 2))

      let self = this;

      Promise.all([
        this.$refs.demandDetailRef.validate(),
        this.$refs.buyerRef.validate(),
        this.$refs.orderRef.validate(),
        this.$refs.skuRef.validate(),
        this.$refs.priceProgramRef.validate(),
        this.$refs.priceSpotRef.validate(),


      ]).then(([demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid]) =>
      {

        //console.log({ demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid })

        if (demandDetailValid && buyerValid && orderValid && skuValid)
        {
          let type_of_demand = this.findFieldByName(this.demand_detail.fields, 'type_of_demand').value

          if ((type_of_demand == "spot" && priceSpotValid) || (type_of_demand == "program" && priceProgramValid))
          {

            if (this.forecast)
            {
              this.rebajarContrato()
            }
            else
            {
              this.createContrato()
            }


          }





        }


      })


    },



  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>